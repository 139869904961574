/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import { Addr, InfoResponse, Uint128 } from '@dao-dao/types'
import {
  ArrayOfTupleOfAddrAndUint128,
  BondingStatusResponse,
  Config,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/NeutronVault'

export interface NeutronVaultReadOnlyInterface {
  contractAddress: string
  config: () => Promise<Config>
  votingPowerAtHeight: ({
    address,
    height,
  }: {
    address: string
    height?: number
  }) => Promise<VotingPowerAtHeightResponse>
  totalPowerAtHeight: ({
    height,
  }: {
    height?: number
  }) => Promise<TotalPowerAtHeightResponse>
  bondingStatus: ({
    address,
    height,
  }: {
    address: string
    height?: number
  }) => Promise<BondingStatusResponse>
  dao: () => Promise<Addr>
  name: () => Promise<String>
  description: () => Promise<String>
  listBonders: ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }) => Promise<ArrayOfTupleOfAddrAndUint128>
  info: () => Promise<InfoResponse>
}
export class NeutronVaultQueryClient implements NeutronVaultReadOnlyInterface {
  client: CosmWasmClient
  contractAddress: string

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.config = this.config.bind(this)
    this.votingPowerAtHeight = this.votingPowerAtHeight.bind(this)
    this.totalPowerAtHeight = this.totalPowerAtHeight.bind(this)
    this.bondingStatus = this.bondingStatus.bind(this)
    this.dao = this.dao.bind(this)
    this.name = this.name.bind(this)
    this.description = this.description.bind(this)
    this.listBonders = this.listBonders.bind(this)
    this.info = this.info.bind(this)
  }

  config = async (): Promise<Config> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {},
    })
  }
  votingPowerAtHeight = async ({
    address,
    height,
  }: {
    address: string
    height?: number
  }): Promise<VotingPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_power_at_height: {
        address,
        height,
      },
    })
  }
  totalPowerAtHeight = async ({
    height,
  }: {
    height?: number
  }): Promise<TotalPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      total_power_at_height: {
        height,
      },
    })
  }
  bondingStatus = async ({
    address,
    height,
  }: {
    address: string
    height?: number
  }): Promise<BondingStatusResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      bonding_status: {
        address,
        height,
      },
    })
  }
  dao = async (): Promise<Addr> => {
    return this.client.queryContractSmart(this.contractAddress, {
      dao: {},
    })
  }
  name = async (): Promise<String> => {
    return this.client.queryContractSmart(this.contractAddress, {
      name: {},
    })
  }
  description = async (): Promise<String> => {
    return this.client.queryContractSmart(this.contractAddress, {
      description: {},
    })
  }
  listBonders = async ({
    limit,
    startAfter,
  }: {
    limit?: number
    startAfter?: string
  }): Promise<ArrayOfTupleOfAddrAndUint128> => {
    return this.client.queryContractSmart(this.contractAddress, {
      list_bonders: {
        limit,
        start_after: startAfter,
      },
    })
  }
  info = async (): Promise<InfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      info: {},
    })
  }
}
export interface NeutronVaultInterface extends NeutronVaultReadOnlyInterface {
  contractAddress: string
  sender: string
  updateConfig: (
    {
      description,
      name,
      owner,
    }: {
      description: string
      name: string
      owner: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  bond: (
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  unbond: (
    {
      amount,
    }: {
      amount: Uint128
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class NeutronVaultClient
  extends NeutronVaultQueryClient
  implements NeutronVaultInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string

  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.updateConfig = this.updateConfig.bind(this)
    this.bond = this.bond.bind(this)
    this.unbond = this.unbond.bind(this)
  }

  updateConfig = async (
    {
      description,
      name,
      owner,
    }: {
      description: string
      name: string
      owner: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_config: {
          description,
          name,
          owner,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  bond = async (
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        bond: {},
      },
      fee,
      memo,
      _funds
    )
  }
  unbond = async (
    {
      amount,
    }: {
      amount: Uint128
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        unbond: {
          amount,
        },
      },
      fee,
      memo,
      _funds
    )
  }
}
