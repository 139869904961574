/**
 * This file was automatically generated by @cosmwasm/ts-codegen@1.10.0.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { UseQueryOptions } from '@tanstack/react-query'

import {
  Addr,
  ArrayOfVotingVault,
  Config,
  InfoResponse,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/NeutronVotingRegistry'
import { getCosmWasmClientForChainId } from '@dao-dao/utils'

import { NeutronVotingRegistryQueryClient } from '../../../contracts/NeutronVotingRegistry'

export const neutronVotingRegistryQueryKeys = {
  contract: [
    {
      contract: 'neutronVotingRegistry',
    },
  ] as const,
  address: (chainId: string, contractAddress: string) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.contract[0],
        chainId,
        address: contractAddress,
      },
    ] as const,
  dao: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'dao',
        args,
      },
    ] as const,
  config: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'config',
        args,
      },
    ] as const,
  votingVaults: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'voting_vaults',
        args,
      },
    ] as const,
  votingPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'voting_power_at_height',
        args,
      },
    ] as const,
  totalPowerAtHeight: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'total_power_at_height',
        args,
      },
    ] as const,
  info: (
    chainId: string,
    contractAddress: string,
    args?: Record<string, unknown>
  ) =>
    [
      {
        ...neutronVotingRegistryQueryKeys.address(chainId, contractAddress)[0],
        method: 'info',
        args,
      },
    ] as const,
}
export const neutronVotingRegistryQueries = {
  dao: <TData = Addr>({
    chainId,
    contractAddress,
    options,
  }: NeutronVotingRegistryDaoQuery<TData>): UseQueryOptions<
    Addr,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.dao(chainId, contractAddress),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).dao(),
    ...options,
  }),
  config: <TData = Config>({
    chainId,
    contractAddress,
    options,
  }: NeutronVotingRegistryConfigQuery<TData>): UseQueryOptions<
    Config,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.config(chainId, contractAddress),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).config(),
    ...options,
  }),
  votingVaults: <TData = ArrayOfVotingVault>({
    chainId,
    contractAddress,
    args,
    options,
  }: NeutronVotingRegistryVotingVaultsQuery<TData>): UseQueryOptions<
    ArrayOfVotingVault,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.votingVaults(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingVaults({
        height: args.height,
      }),
    ...options,
  }),
  votingPowerAtHeight: <TData = VotingPowerAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: NeutronVotingRegistryVotingPowerAtHeightQuery<TData>): UseQueryOptions<
    VotingPowerAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.votingPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).votingPowerAtHeight({
        address: args.address,
        height: args.height,
      }),
    ...options,
  }),
  totalPowerAtHeight: <TData = TotalPowerAtHeightResponse>({
    chainId,
    contractAddress,
    args,
    options,
  }: NeutronVotingRegistryTotalPowerAtHeightQuery<TData>): UseQueryOptions<
    TotalPowerAtHeightResponse,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.totalPowerAtHeight(
      chainId,
      contractAddress,
      args
    ),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).totalPowerAtHeight({
        height: args.height,
      }),
    ...options,
  }),
  info: <TData = InfoResponse>({
    chainId,
    contractAddress,
    options,
  }: NeutronVotingRegistryInfoQuery<TData>): UseQueryOptions<
    InfoResponse,
    Error,
    TData
  > => ({
    queryKey: neutronVotingRegistryQueryKeys.info(chainId, contractAddress),
    queryFn: async () =>
      new NeutronVotingRegistryQueryClient(
        await getCosmWasmClientForChainId(chainId),
        contractAddress
      ).info(),
    ...options,
  }),
}
export interface NeutronVotingRegistryReactQuery<TResponse, TData = TResponse> {
  chainId: string
  contractAddress: string
  options?: Omit<
    UseQueryOptions<TResponse, Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  > & {
    initialData?: undefined
  }
}
export interface NeutronVotingRegistryInfoQuery<TData>
  extends NeutronVotingRegistryReactQuery<InfoResponse, TData> {}
export interface NeutronVotingRegistryTotalPowerAtHeightQuery<TData>
  extends NeutronVotingRegistryReactQuery<TotalPowerAtHeightResponse, TData> {
  args: {
    height?: number
  }
}
export interface NeutronVotingRegistryVotingPowerAtHeightQuery<TData>
  extends NeutronVotingRegistryReactQuery<VotingPowerAtHeightResponse, TData> {
  args: {
    address: string
    height?: number
  }
}
export interface NeutronVotingRegistryVotingVaultsQuery<TData>
  extends NeutronVotingRegistryReactQuery<ArrayOfVotingVault, TData> {
  args: {
    height?: number
  }
}
export interface NeutronVotingRegistryConfigQuery<TData>
  extends NeutronVotingRegistryReactQuery<Config, TData> {}
export interface NeutronVotingRegistryDaoQuery<TData>
  extends NeutronVotingRegistryReactQuery<Addr, TData> {}
